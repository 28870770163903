<template>
  <div v-if="modalConfirmationOptioned">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >

        <div
          class="inline-block align-bottom bg-white rounded-lg px-6 pt-5 pb-6 text-left overflow-hidden shadow-xl transform transition-all md:my-4 sm:align-middle md:w-1/3 w-full  sm:p-6"
        >
          <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              @click="toggleConfirmationModal(false)"
              type="button"
              class="bg-white rounded-md text-gray-400 hover:text-danger focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <span class="sr-only">Close</span>
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="sm:flex sm:items-start">
            <div
              :class="
                `mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${baseColor}-100 sm:mx-0 sm:h-10 sm:w-10`
              "
            >
              <!-- Heroicon name: outline/exclamation -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                :class="`h-6 w-6 text-${baseColor}-600`"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                />
              </svg>
            </div>
            <div class="mt-5 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-2xl leading-6 font-medium text-gray-800 mt-1"
                id="modal-title"
              >
                {{ title }}
              </h3>
              <div class="mt-3">
                <p class="text-base text-gray-500">
                  {{ msg }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              @click="changeStatus()"
              type="button"
              :class="
                `w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-${baseColor}-500 text-base font-medium text-gray-900 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`
              "
            >
              Confirmar
            </button>
            <button
              @click="toggleConfirmationModal(false)"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-white hover:bg-danger focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sentryCaptureException } from "../../../helpers/Sentry";
import quoteApi from "../../quotes/services/quotes.api";
import { email } from "../../../services";

export default {
  props: {
    propertySelected: Array,
    msg: String,
    title: String,
    baseColor: String,
    statusForUpdate: String
  },
  computed: {
    ...mapState("optioned", ["modalConfirmationOptioned"])
  },
  methods: {
    async changeStatus() {
      try {
        if (!this.propertySelected.length) {
          this.$store.commit("optioned/modalConfirmationOptioned", false);
          this.$vs.notify({
            title: "Actualizacion incorrecta",
            color: "danger"
          });

          return;
        }

        const quoteId = this.propertySelected[0].id;
        if(this.statusForUpdate === "OPEN") {
          await quoteApi.optionQuote(quoteId);
          email.sendEmail("optioned", `{"quoteId": "${quoteId}"}`);
        }
        else {
          await quoteApi.unOptionQuote(quoteId);
        }

        this.$store.commit("optioned/modalConfirmationOptioned", false);

        this.$root.$emit("reload-optioned");

        if (this.statusForUpdate !== "AVAILABLE") {
          this.$root.$emit("reload-quote");
        }

        this.$vs.notify({
          title: "Actualizacion correcta",
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success"
        });
      } catch (e) {
        sentryCaptureException(e, "optioned");
      }
    },
    toggleConfirmationModal(v) {
      this.$store.commit("optioned/modalConfirmationOptioned", v);
    }
  }
};
</script>
